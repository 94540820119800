<template>
  <div class="all_main_wrap">
    <HeaderBox>
      <template #title>
        <span @click="$router.go(-1)">作业管理</span>
         / 学员配置
      </template>
      <template #input>
        <a-button type="primary" class="all_boder_btn" @click="isModalShow=true" v-hasPermi="['exam:homework:assignuser']">新建</a-button>
      </template>
    </HeaderBox>
    <!-- 表格列表 -->
    <div class="table-box">
      <h4>已发布人员<span>（当前已发布{{total}}人）</span></h4>
      <a-table
      class="table-template"
      :loading="loading"
      :rowKey="item=>item.userId"
      :columns="columns"
      :data-source="tableData"
      @change="onPage"
      :pagination="{
        total:total,
        current:queryParams.pageNum,  
        defaultPageSize:queryParams.pageSize, 
        showSizeChanger: true,
        showTotal: function(total, range){
        return `共${total}条`}} ">
        <template slot="index" slot-scope="item, row, i">
          <div style="text-align: center;">
            {{ (queryParams.pageNum - 1) * queryParams.pageSize + i + 1 }}
          </div>
        </template>
      </a-table>
    </div>
    <!-- 未分配学生列表 -->
    <a-modal v-model="isModalShow" align="center" width="1000px" title="选择学员" >
      <div class="all_content_box">
        <div class="flex">
          <a-input class="input" v-model="studentParams.userName" placeholder="请输入姓名" />
          <a-input class="input" v-model="studentParams.mobile" placeholder="请输入手机号" />
          <a-input class="input" v-model="studentParams.className" placeholder="请输入班期" />
          <a-range-picker
            class="input"
            v-model="pickerData"
            valueFormat="YYYY-MM-DD"
            :placeholder="['考试开始日期','考试结束日期']"
          ></a-range-picker>
          <a-button type="primary" class="all_boder_btn" @click="studentParams.pageNum = 1, getNoStudentList()">搜索</a-button>
        </div>
        <a-table
        class="table-template"
        :loading="sutdentLoad"
        :rowKey="(item)=>item.userId"
        :columns="columns1"
        :data-source="UnStudentList"
        @change="onUnStudentPage"
        :row-selection="{
          selectedRowKeys: selectedSignUpRowKeys,
          onChange: onSelect,
        }"
        :pagination="{
          total:UnStudentTotal,
          current:studentParams.pageNum,  
          defaultPageSize:studentParams.pageSize, 
          showSizeChanger: true,
          showTotal: function(total, range){
          return `共${total}条`}} ">
          <!-- 序号 -->
          <template slot="index" slot-scope="item, row, i">
            <div style="text-align: center;">
              {{ (studentParams.pageNum - 1) * studentParams.pageSize + i + 1 }}
            </div>
          </template>

          <!-- 班期 -->
          <template slot="className" slot-scope="text">
            <span>{{text?text:'-'}}</span>
          </template>

          <!-- 考试日期 -->
          <template slot="examDate" slot-scope="text">
            <span>{{text?text:'-'}}</span>
          </template>

        </a-table>

        <div class="grey" v-show="selectedSignUpRowKeys.length">
          已选中学员{{selectedSignUpRowKeys.length}}人：
          <span v-for="(item,index) in selectSutdenList" :key="item.userId + 'key'">{{item.name}}<a-icon @click="onDel(item.userId, index)" class="icon" type='close-circle'/></span>
        </div>
      </div>
      <template slot="footer">
        <a-button type="primary" :loading="sutdentLoad" @click="onAdd()">添加</a-button>
        <a-button  @click="isModalShow=false">取消</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
const columns = [
  {
    title: "序号",
    width: "80px",
    align:'center',
    dataIndex: "index",
    scopedSlots: { customRender: 'index' }

  },
  {
    title: "姓名",
    align:'center',
    dataIndex: "name"
  },
  {
    title: "手机号",
    align:'center',
    dataIndex: "mobile"
  }
];

const columns1 = [
  {
    title: "序号",
    width: "80px",
    align:'center',
    dataIndex: "index",
    scopedSlots: { customRender: 'index' }

  },
  {
    title: "姓名",
    align:'center',
    dataIndex: "name"
  },
  {
    title: "手机号",
    align:'center',
    dataIndex: "mobile"
  },
  {
    title: "班期",
    align:'center',
    dataIndex: "className",
    scopedSlots: { customRender: 'className' }
  },
  {
    title: "考试日期",
    align:'center',
    dataIndex: "examDate",
    scopedSlots: { customRender: 'examDate' }
  }
];

import HeaderBox from '@/components/HeaderBox.vue'

export default {
  // 可用组件的哈希表
  components: { HeaderBox },
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      columns, // 表头
      columns1,
      tableData:[], // 列表数据
      loading:false, // 列表加载
      total: 0, // 总数量
      queryParams:{
        configId: this.$route.query.configId,  //配置id,
        pageNum: 1, // 页码
        pageSize: 10, // 页数
      },

      pickerData:[],

      isModalShow:false,  // 未分配学员弹窗
      selectedSignUpRowKeys:[],  // 已选的学员id
      selectSutdenList: [],  // 已选的学员列表
      selectedSignUpList: [],  // 已选的学员列表

      studentParams:{
        courseId: this.$route.query.courseId,  // 课程id,
        userName: '',  // 用户名称
        mobile:'', // 用户手机号
        className: '',  // 班期名称
        examStartTime: null,  // 考试开始时间
        examEndTime: null,  // 考试结束时间
        pageNum: 1, // 页码
        pageSize: 10, // 页数
      },
      UnStudentList:[],
      UnStudentTotal:0,
      sutdentLoad:false
    }
  },
  // 事件处理器
  methods: {
    // 分页
    onPage(e){
      this.queryParams.pageNum = e.current
      this.queryParams.pageSize = e.pageSize
      this.getManageList()
    },

    // 分页
    onUnStudentPage(e){
      this.studentParams.pageNum = e.current
      this.studentParams.pageSize = e.pageSize
      this.getNoStudentList()
    },

    // 选择未分布学员
    onSelect(e,v){
      this.selectedSignUpRowKeys = e
      this.selectedSignUpList.push(...v)

      // 所选人员去重
      function distinct1(arr,key){
        var newobj = {},newArr = [];
          for(var i=0;i<arr.length;i++){
              var item = arr[i];
            if(!newobj[item[key]]){
                  newobj[item[key]] = newArr.push(item);
            }
          }
          return newArr;
      }
      var filterArry = distinct1(this.selectedSignUpList,'userId')


      this.selectedSignUpList = filterArry.filter((item) => { return e.indexOf(item.userId) != -1 })
      this.selectSutdenList = this.selectedSignUpList
    },

    // 删除并过滤已选的学员
    onDel(userid,index){
      this.selectedSignUpRowKeys = this.selectedSignUpRowKeys.filter((item) => { return item != userid})
      this.selectSutdenList = this.selectSutdenList.filter((item) => { return item.userId != userid})
      this.selectedSignUpList = this.selectSutdenList
    },

    // 添加分配学员
    onAdd(){
      if(!this.selectSutdenList.length){
        this.$message.error('请选择学员');
        return
      }

      var form = {
        configId: this.$route.query.configId,
        examinees: []
      }

      this.selectSutdenList.forEach(element => {
        form.examinees.push({
          mobile: element.mobile,
          mobileEncrypt: element.mobileEncrypt,
          name: element.name,
          userId: element.userId,
        })
      })

      this.sutdentLoad = true
      this.$ajax({
        method: 'post',
        url:"/hxclass-management/homeworkAnswer/issue",
        params: form
      }).then(res => {
        this.sutdentLoad = false
        if (res.code == 200 && res.success) {
          this.$message.success('发布成功');
          this.isModalShow = false
          this.UnStudentList = []
          this.selectedSignUpRowKeys = []
          this.selectSutdenList = []
          this.getManageList()
        } else {
          this.$message.error(res.message);
        }
      })
    },

    // 查询列表
    getManageList(){
      this.loading = true
      this.$ajax({
        method: 'get',
        url:"/hxclass-management/homeworkConfig/getAssignedList",
        params: this.queryParams
      }).then(res => {
        this.loading = false
        if (res.code == 200 && res.success) {
          this.tableData = res.data.records
          this.total = res.data.total
        } else {
          this.tableData = []
          this.total = 0
          this.$message.error(res.message);
        }
      })
    },

    // 查询未分配的学员列表
    getNoStudentList(){
      this.studentParams.examStartTime = (this.pickerData.length ? this.pickerData[0] : null)
      this.studentParams.examEndTime = (this.pickerData.length ? this.pickerData[1] : null)
      this.studentParams.configId = this.$route.query.configId;
      this.sutdentLoad = true
      this.$ajax({
        method: 'get',
        url:"/hxclass-management/homeworkConfig/getUnassignedList",
        params: this.studentParams
      }).then(res => {
        this.sutdentLoad = false
        if (res.code == 200 && res.success) {
          this.UnStudentList = res.data.records
          this.UnStudentTotal = res.data.total
        } else {
          this.UnStudentList = []
          this.UnStudentTotal = 0
          this.$message.error(res.message);
        }
      })
    }
  },
  // 生命周期-实例创建完成后调用
  created () {
  },
  // 生命周期-实例挂载后调用
  mounted () {
    this.getManageList()
  },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
.btn{
  margin-right: 24px;
}
.table-box{
  /deep/ .ant-tabs-bar{
    border-bottom:none
  }
  h4{
    font-size: 18px;
    font-weight: bold;
    margin: 15px 0;
    color: #666666;
    span{
      font-size: 14px;
      font-weight: 400;
    }
  }
}
.all_content_box{
  text-align: left;
  .flex{
    display: flex;
    margin-bottom: 20px;
    .input{
      margin-right: 20px;
      width: 30%;
    }
  }
  .grey{
    margin-top: 20px;
    background: #f9f9f9;
    min-height: 100px;
    padding: 12px;
    border-radius: 3px;
    color: #333;
    span{
      display: inline-block;
      font-size: 14px;
      margin-right: 10px;
      margin-bottom: 10px;
      padding: 4px 13px;
      background: #ffffff;
      border-radius: 30px;
      .icon{
        margin-left: 10px;
        cursor: pointer;
      }
    }
  }
}
.table-template{
  margin-top: 0;
}
/deep/ .ant-modal-footer{
  text-align: center;
}
</style>
